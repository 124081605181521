const epochNow = () => {
    return Math.floor(Date.now() / 1000);
};

const epochToday = () => {
    return Math.floor(epochNow() / 86400) * 86400;
}

export const formatDateStringYYYYMMDD = (date: Date): string => {
    const formattedDate = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
    ].join("");

    return formattedDate;
};

/**
 * Generates a readable string for standard time
 * (Ex: "8:34am", "2:05pm")
 * @param d The Date to build the string for.
 */
export const formatTimeStringFromDate = (d: Date): string => {
    const hr = d.getHours();
    const units = hr >= 12 ? "pm" : "am";

    const hrStr = `${hr === 12 ? hr : hr % 12}`;

    const min = d.getMinutes();
    const minStr = min < 10 ? `0${min}` : `${min}`;

    return `${hrStr}:${minStr}${units}`;
};

/**
 * Converts an epoch timestamp to a readable date string.
 * @param epochTimestamp The epoch timestamp to convert.
 * @param locale The locale to use for formatting the date string.
 */
export function formatEpochToDate(epochTimestamp: number, locale: string = "en-US"): string {
    const date = new Date(epochTimestamp * 1000);

    return date.toLocaleString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    });
}

export {epochNow, epochToday};
